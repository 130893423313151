var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Responsive images" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeResponsive) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v("Images in BootstrapVue can be made responsive with the ")
        ]),
        _c("code", [_vm._v("fluid")]),
        _c("span", [_vm._v(" prop (which sets ")]),
        _c("code", [_vm._v("max-width: 100%; height: auto;")]),
        _c("span", [
          _vm._v(
            " via CSS classes) so that it scales with the parent element - up to the maximum native width of the image. "
          )
        ])
      ]),
      _c(
        "div",
        [
          _c("h6", [
            _vm._v("Small image with "),
            _c("code", [_vm._v("fluid")]),
            _vm._v(":")
          ]),
          _c("b-img", {
            attrs: {
              src: require("@/assets/images/slider/03.jpg"),
              fluid: "",
              alt: "Fluid image"
            }
          }),
          _c("h6", { staticClass: "my-1" }, [
            _vm._v(" Small image with "),
            _c("code", [_vm._v("fluid-grow")]),
            _vm._v(": ")
          ]),
          _c("b-img", {
            attrs: {
              src: require("@/assets/images/slider/03.jpg"),
              "fluid-grow": "",
              alt: "Fluid-grow image"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }