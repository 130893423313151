var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Rounded corners" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeRoundedCorner) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [
          _vm._v(
            "You can control which corners are rounded by setting the rounded prop to one of the following values:"
          )
        ]),
        _c("code", [_vm._v("true,false,top,right,bottom,left,circle,0")])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-img",
            _vm._b(
              {
                staticClass: "d-inline-block",
                attrs: { rounded: "", alt: "Rounded image" }
              },
              "b-img",
              _vm.mainProps,
              false
            )
          ),
          _c(
            "b-img",
            _vm._b(
              {
                staticClass: "d-inline-block",
                attrs: { rounded: "top", alt: "Top-rounded image" }
              },
              "b-img",
              _vm.mainProps,
              false
            )
          ),
          _c(
            "b-img",
            _vm._b(
              {
                staticClass: "d-inline-block",
                attrs: { rounded: "right", alt: "Right-rounded image" }
              },
              "b-img",
              _vm.mainProps,
              false
            )
          ),
          _c(
            "b-img",
            _vm._b(
              {
                staticClass: "d-inline-block",
                attrs: { rounded: "bottom", alt: "Bottom-rounded image" }
              },
              "b-img",
              _vm.mainProps,
              false
            )
          ),
          _c(
            "b-img",
            _vm._b(
              {
                staticClass: "d-inline-block",
                attrs: { rounded: "left", alt: "Left-rounded image" }
              },
              "b-img",
              _vm.mainProps,
              false
            )
          ),
          _c(
            "b-img",
            _vm._b(
              {
                staticClass: "d-inline-block",
                attrs: { rounded: "circle", alt: "Circle image" }
              },
              "b-img",
              _vm.mainProps,
              false
            )
          ),
          _c(
            "b-img",
            _vm._b(
              {
                staticClass: "d-inline-block",
                attrs: { rounded: "0", alt: "Not rounded image" }
              },
              "b-img",
              _vm.mainProps,
              false
            )
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }