var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Images" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v("Documentation and examples for opting images (via ")
        ]),
        _c("code", [_vm._v("<b-img>")]),
        _c("span", [
          _vm._v(
            " component) into responsive behavior (so they never become larger than their parent elements), optionally adding lightweight styles to them — all via props. "
          )
        ])
      ]),
      _c("b-img", {
        staticClass: "w-100",
        attrs: {
          src: require("@/assets/images/banner/banner-6.jpg"),
          fluid: "",
          alt: "Responsive image"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }