var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Aligning images" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeAlignment) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Align images with the boolean props ")]),
        _c("code", [_vm._v("left")]),
        _c("span", [_vm._v(" (floats left) ")]),
        _c("code", [_vm._v("right")]),
        _c("span", [_vm._v("(floats right), and ")]),
        _c("code", [_vm._v("center")]),
        _c("span", [
          _vm._v(
            "(auto left+right margins). You can also center images by placing them in a container that has the class "
          )
        ]),
        _c("code", [_vm._v("text-center.")])
      ]),
      _c(
        "div",
        { staticClass: "clearfix" },
        [
          _c("b-img", {
            staticClass: "mb-1 mb-sm-0",
            attrs: {
              left: "",
              height: "120",
              src: require("@/assets/images/banner/banner-34.jpg"),
              alt: "Left image"
            }
          }),
          _c("b-img", {
            staticClass: "mb-1 mb-sm-0",
            attrs: {
              right: "",
              height: "120",
              src: require("@/assets/images/banner/banner-34.jpg"),
              alt: "Right image"
            }
          }),
          _c("b-img", {
            staticClass: "mb-1 mb-sm-0",
            attrs: {
              center: "",
              height: "120",
              src: require("@/assets/images/banner/banner-34.jpg"),
              alt: "Center image"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }