var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Blank (or solid color) images" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBlank) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("code", [_vm._v("<b-img>")]),
        _c("span", [
          _vm._v(
            " provides built-in support for generating blank images (transparent by default) of any width and height, by setting the "
          )
        ]),
        _c("code", [_vm._v("blank")]),
        _c("span", [_vm._v(" prop, and specifying ")]),
        _c("code", [_vm._v("width")]),
        _c("span", [_vm._v(" and ")]),
        _c("code", [_vm._v("height")]),
        _c("span", [
          _vm._v("values (in pixels). You can apply any of the other ")
        ]),
        _c("code", [_vm._v("<b-img>")]),
        _c("span", [
          _vm._v(" props to change the style/behavior of the generated image.")
        ])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-img",
            _vm._b(
              {
                staticClass: "d-inline-block border",
                attrs: { rounded: "", alt: "Transparent image" }
              },
              "b-img",
              _vm.mainProps,
              false
            )
          ),
          _c(
            "b-img",
            _vm._b(
              {
                staticClass: "d-inline-block",
                attrs: {
                  "blank-color": "#5A8DEE",
                  rounded: "",
                  alt: "HEX shorthand color image (#777)"
                }
              },
              "b-img",
              _vm.mainProps,
              false
            )
          ),
          _c(
            "b-img",
            _vm._b(
              {
                staticClass: "d-inline-block",
                attrs: {
                  "blank-color": "#82868b",
                  rounded: "",
                  alt: "Named color image (red)"
                }
              },
              "b-img",
              _vm.mainProps,
              false
            )
          ),
          _c(
            "b-img",
            _vm._b(
              {
                staticClass: "d-inline-block",
                attrs: {
                  "blank-color": "#39DA8A",
                  rounded: "",
                  alt: "Named color image (black)"
                }
              },
              "b-img",
              _vm.mainProps,
              false
            )
          ),
          _c(
            "b-img",
            _vm._b(
              {
                staticClass: "d-inline-block",
                attrs: {
                  "blank-color": "#FDAC41",
                  alt: "RGBa color image",
                  rounded: ""
                }
              },
              "b-img",
              _vm.mainProps,
              false
            )
          ),
          _c(
            "b-img",
            _vm._b(
              {
                staticClass: "d-inline-block",
                attrs: {
                  "blank-color": "#FF5B5C",
                  rounded: "",
                  alt: "HEX color image"
                }
              },
              "b-img",
              _vm.mainProps,
              false
            )
          ),
          _c(
            "b-img",
            _vm._b(
              {
                staticClass: "d-inline-block",
                attrs: {
                  "blank-color": "#00CFDD",
                  rounded: "",
                  alt: "HEX shorthand color (#88f)"
                }
              },
              "b-img",
              _vm.mainProps,
              false
            )
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }