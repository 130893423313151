var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Lazy loaded images" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeLazy) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Use our complementary ")]),
        _c("code", [_vm._v("<b-img-lazy>")]),
        _c("span", [_vm._v(" image component (based on ")]),
        _c("code", [_vm._v("<b-img>")]),
        _c("span", [
          _vm._v(
            ") to lazy load images as they are scrolled into view (or within "
          )
        ]),
        _c("code", [_vm._v("offset")]),
        _c("span", [_vm._v(" pixels of the viewport).")])
      ]),
      _c("b-img-lazy", {
        staticClass: "w-100",
        attrs: {
          src: require("@/assets/images/banner/banner-6.jpg"),
          fluid: ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }